import { useTheme } from '@mui/material/styles';
import { Grid, Card, CardContent, useMediaQuery, Button, Typography, Box } from '@mui/material';
// import { IframeModal } from '~/content/components/Modal/IframeModal';
import clientBayern from '~/content/svg/clients/bayern_staatsregierung.webp';
import { Link } from '@remix-run/react';
import case_study_1 from '~/content/images/index_page/case_study_1_best.webp';
// import { ImageLoader } from '~/content/components/ImageLoader';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from '@remix-run/react';

import { useRef, useState } from 'react';

const CaseStudy1 = (): JSX.Element => {
	const case_study_1Ref = useRef(null);
	const clientBayernRef = useRef(null);
	let { t } = useTranslation(['index']);
	const theme = useTheme();
	const { userLanguage } = useLoaderData();
	const link_prefix = userLanguage === 'en' ? '/en' : '';

	const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	// const [open, setOpen] = useState(false);
	// const handleOpen = () => setOpen(true);
	// const handleClose = () => setOpen(false);

	return (
		<>
			{/* <IframeModal
				open={open}
				handleClose={handleClose}
				handleOpen={handleOpen}
				URL='https://'
				headline='1'
				id={undefined}
			/> */}
			<Box
				padding={{ xs: 2, sm: 4 }}
				borderRadius={0}
				// sx={{ border: '0px  solid', borderColor: theme.palette.divider }}
				// light: E5E7EA  Dark: 33353E
				bgcolor={
					//@ts-ignore
					theme.palette.background.level2

					// FBE1E8  '#C9A3A4' : '#7E6666'  gryisch  E5E7EA   CED4DA
					// colors.red[100] : colors.red[900]
				}
				// data-aos={'fade-up'}
			>
				<Grid container spacing={isMd ? 4 : 2} flexDirection={{ xs: 'column-reverse', md: 'row' }}>
					<Grid item xs={12} md={8}>
						<Grid container spacing={isMd ? 4 : 2}>
							<Grid
								item
								xs={12}
								// sx={{
								//   '& .lazy-load-image-background.lazy-load-image-loaded': {
								//     width: '100%',
								//     height: '100%',
								//   },
								// }}
							>
								{/* <ImageLoader delay={false} intersectViewportCheck={true} childRef={case_study_1Ref}> */}
								<Box
									ref={case_study_1Ref}
									component={'img'}
									height={1}
									width={1}
									src={case_study_1}
									alt='Professionelle Website Entwicklung in Frankfurt für Gesundheitswesen, Infrastrukur und Behörden'
									borderRadius={0}
									maxWidth={1}
									maxHeight={400}
									sx={{
										objectFit: 'cover',
										filter:
											theme.palette.mode === 'light' ? 'brightness(1.05)' : 'brightness(0.85)',
									}}
								/>
								{/* </ImageLoader> */}
							</Grid>
							<Grid item xs={6}>
								<Typography component={'h3'} variant={'h6'} fontWeight={700} gutterBottom>
									{t('caseStudy_1_Subheadline_1')}
								</Typography>
								<Typography component={'p'}>{t('caseStudy_1_SubBody_1')}</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography component={'h4'} variant={'h6'} fontWeight={700} gutterBottom>
									{t('caseStudy_1_Subheadline_2')}
								</Typography>
								<Typography component={'p'}>{t('caseStudy_1_SubBody_2')}</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4}>
						<Box
							display={'flex'}
							flexDirection={'column'}
							justifyContent={{ xs: 'flex-start', md: 'space-between' }}
							height={1}
						>
							<Box>
								{/* <div
									style={{
										position: 'absolute',
										width: '20px',
										height: '20px',
										backgroundColor: '#D4B5B6',
										right: 0,
										top: 0
									}}
								></div> */}

								<Typography component={'h3'} variant={'h4'} fontWeight={700} gutterBottom>
									{t('caseStudy_1_1_Headline')}
									{/* <br></br>
									{t('caseStudy_1_2_Headline')} */}
								</Typography>
								<Typography
								// color={
								//   theme.palette.mode === 'light'
								//     ? 'text.secondary'
								//     : 'text.primary'
								// }
								>
									{t('caseStudy_1_Body')}
								</Typography>

								<Link
									to={link_prefix + '/websites/'}
									prefetch='intent'
									style={{
										textDecoration: 'none',
									}}
									onClick={(e: any) => {
										e.stopPropagation();
										// e.preventDefault();
									}}
								>
									<Button
										component={'div'}
										size={'medium'}
										// variant='outlined'
										sx={{ marginTop: 1, borderRadius: 0 }}
										// change  font color
										color={theme.palette.mode === 'light' ? 'primary' : 'primary'}
										endIcon={
											<Box
												component={'svg'}
												xmlns='http://www.w3.org/2000/svg'
												fill={theme.palette.secondary.main}
												viewBox='0 0 48 48'
												stroke={theme.palette.secondary.main}
												width={24}
												height={24}
											>
												<path d='m28.05 35.9-2.15-2.1 8.4-8.4H8v-3h26.3l-8.45-8.45 2.15-2.1L40.05 23.9Z' />
											</Box>
										}
									>
										{t('learnMore')}
									</Button>
								</Link>
							</Box>
							<Box
								borderRadius={0}
								component={Card}
								marginTop={{ xs: 2, md: 0 }}
								boxShadow={0}
								bgcolor={theme.palette.mode === 'light' ? '' : theme.palette.secondary.dark}
								sx={{
									display: isMd ? 'block' : 'none',
								}}
							>
								<Grid container alignItems={'end'} justifyContent={'end'}>
									<Grid item m={1} mb={0}>
										<Link
											to={link_prefix + '/references/stk-website/'}
											prefetch='intent'
											style={{
												textDecoration: 'none',
											}}
											onClick={(e: any) => {
												e.stopPropagation();
												// e.preventDefault();
											}}
										>
											<Button
												// onClick={handleOpen}

												size={'small'}
												// sx={{ marginTop: 2, marginBottom: 1, marginLeft: 20, borderRadius: 0 }}
												// color={theme.palette.mode === "light" ? "primary" : "primary"}

												color={'primary'}
												// endIcon={
												// 	<Box
												// 		component={'svg'}
												// 		xmlns='http://www.w3.org/2000/svg'
												// 		// fill={theme.palette.secondary.main}
												// 		viewBox='0 0 48 48'
												// 		// stroke={theme.palette.secondary.main}
												// 		width={20}
												// 		height={20}
												// 	>
												// 		<path d='m28.05 35.9-2.15-2.1 8.4-8.4H8v-3h26.3l-8.45-8.45 2.15-2.1L40.05 23.9Z' />
												// 	</Box>
												// }

												endIcon={
													<Box
														sx={{ position: 'relative', bottom: '1px' }}
														component={'svg'}
														xmlns='http://www.w3.org/2000/svg'
														fill='currentColor'
														viewBox='0 0 24 24'
														stroke='currentColor'
														width={15}
														height={15}
													>
														<path d='M6 6v2h8.59L5 17.59 6.41 19 16 9.41V18h2V6z' />
													</Box>
												}
											>
												{t('viewCustomerProject')}
											</Button>
										</Link>
									</Grid>
								</Grid>
								<CardContent
									sx={{ padding: { xs: 2, sm: 4 }, paddingTop: { xs: 2, sm: 2, md: 2 } }}
								>
									{/* <ImageLoader
										delay={true}
										intersectViewportCheck={true}
										childRef={clientBayernRef}
									> */}
									<Box
										ref={clientBayernRef}
										component={'img'}
										height={1}
										width={1}
										src={clientBayern}
										alt='Beauftragter für Bürokratieabbau der bayerischen Staatsregierung und Kunde von NorthRock software'
										maxWidth={220}
										marginBottom={2}
										marginLeft={1}
										sx={{
											filter:
												theme.palette.mode === 'dark'
													? 'brightness(0) invert(1)'
													: 'contrast(0) brightness(0.6)',

											// filter: theme.palette.mode === 'dark' ? 'brightness(0.9)' : 'none',
										}}
									/>
									{/* </ImageLoader> */}

									<Box position={'relative'}>
										<Box
											color={theme.palette.mode === 'light' ? '#E8E8E8' : '#53555C'}
											component={'svg'}
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 32 40'
											sx={{
												position: 'absolute',
												width: 36,
												height: 'auto',
												top: -40,
												right: -15,
												opacity: 1,
												userSelect: 'none',
												pointerEvents: 'none',
												fill: 'currentColor',
												stroke: 'currentColor',
											}}
										>
											<g transform='translate(-488 -626)'>
												<path
													data-name='Path 103'
													d='M515,1636l4-8h-6v-12h12v12l-4,8Zm-14,0,4-8h-6v-12h12v12l-4,8Z'
													transform='translate(-8 -984)'
													fillRule='evenodd'
												/>
											</g>
										</Box>
										{/* <Box
											component={'img'}
											src={quote}
											sx={{
												position: 'absolute',
												width: 40,
												height: 'auto',
												top: -35,
												right: -12,
												opacity: 0.09,
												userSelect: 'none',
												pointerEvents: 'none',
											}}
										/> */}
										<Typography
											component={'p'}
											variant={'body1'}
											// position={'relative'}
											sx={
												{
													// color: 'white',
													// zIndex: 1,
													// mixBlendMode: 'difference',
												}
											}
										>
											{t('caseStudy_1_CustomerBody')}
										</Typography>
									</Box>

									<Box marginTop={{ xs: 2, sm: 4 }}>
										<Typography component={'h4'} variant={'subtitle1'} sx={{ fontWeight: 700 }}>
											{t('caseStudy_1_CustomerName')}
										</Typography>
										<Typography color='text.secondary'>{t('caseStudy_1_CustomerInfo')}</Typography>
									</Box>
								</CardContent>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default CaseStudy1;

import { startTransition, useEffect, useState, useRef } from 'react';
import { IconButton, useMediaQuery, Divider, Button, Typography, Box } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { Container } from '~/content/components/Container.tsx';
// import { useHydrated } from 'remix-utils/use-hydrated';
import { Link } from '@remix-run/react';
// import { motion } from 'framer-motion';
import { m, LazyMotion } from 'framer-motion';
const loadFeatures = () => import('~/utils/framerFeatures').then((res) => res.domAnimation);
// import cover from '~/content/images/index_page/NorthRock_Entwicklung.webp';
// import ImageLoader from '~/content/components/ImageLoader';

import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
// import PauseIcon from '@mui/icons-material/Pause';
// "C:\projects\nrs\nrs-website\app\content\svg\icons\pause-icon.svg"
// import pauseIcon from '~/content/svg/icons/pause-icon.svg';
import heroVid_mobil from '~/content/videos/northrock_software_introduction_mobil.webm';
import heroVid_desktop from '~/content/videos/northrock_software_introduction_desktop.webm';
import hero_poster from '~/content/images/index_page/hero_poster3.webp';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from '@remix-run/react';
import { useCookieConsent } from '~/content/globalStore/ccStore';
// import { create } from 'zustand';

// import ThreeeBoxs from './ThreeeBoxs';
// import ThreeDistortedBlob from './ThreeDistortedBlob';
// import ThreeRock from './ThreeRock';
// import NRSPlane from './Plane/index';

const Hero = (): JSX.Element => {
	let { t } = useTranslation('index');
	let { i18n } = useTranslation();
	const language = i18n.language;

	const [videoPlaying, setVideoPlaying] = useState(true);
	const { cookieConsentBoxVisible } = useCookieConsent();
	const [triggerAnimation, setTriggerAnimation] = useState(false);

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		// defaultMatches: true,
	});
	const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: false,
	});
	// const isSmUp = useMediaQuery(theme.breakpoints.up('xs'), {
	// 	defaultMatches: false,
	// });
	const isXs = useMediaQuery(theme.breakpoints.down('xs'), {
		defaultMatches: false,
	});

	//@ts-ignore
	const { userLanguage, IS_MOBILE } = useLoaderData();
	// console.log({ IS_MOBILE });
	const link_prefix = userLanguage === 'en' ? '/en' : '';

	const coverRef = useRef<HTMLVideoElement | null>(null);

	useEffect(() => {
		if (
			!cookieConsentBoxVisible &&
			localStorage.getItem('nrs-dsgvo') != null &&
			!triggerAnimation
		) {
			// console.log('trigger');
			startTransition(() => {
				setTriggerAnimation(true);
			});
		} else if (isMd) {
			// console.log('trigger');
			startTransition(() => {
				setTriggerAnimation(true);
			});
		}
	}, [cookieConsentBoxVisible]);

	// React.useEffect(() => {
	// 	// console.log(cookieConsentIsSetTo);
	// 	// console.log(cookieConsentIsSetTo && !isMd);
	// 	console.log(!isMd);
	// }, []);

	useEffect(() => {
		if (coverRef.current != null) coverRef.current.playbackRate = 1;
	}, []);
	useEffect(() => {
		if (coverRef.current != null) {
			// console.log({ isSm });
			if (isSm) {
				coverRef.current.src = heroVid_desktop;
			} else {
				coverRef.current.src = heroVid_mobil;
			}
		}
	}, [isSm]);

	// const usePreviousValue = (value: any) => {
	// 	const ref = React.useRef();
	// 	React.useEffect(() => {
	// 		ref.current = value;
	// 	});
	// 	return ref.current;
	// };
	// const prevValue = usePreviousValue(theme.palette.mode);

	const variants = (rnd: any) => {
		return {
			inactive: {
				opacity: 1,
			},
			active: {
				// initial: { opacity: 0 },
				opacity: 1,
				transition: { repeat: 1, duration: 0.1, delay: 0 + rnd },
			},
		};
	};

	return (
		<Box
			sx={{
				width: 1,
				height: 1,
				overflow: 'hidden',
			}}
		>
			<Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
				<Box
					display={'flex'}
					flexDirection={{ xs: 'column', md: 'row' }}
					position={'relative'}
					minHeight={{ md: 600 }}
				>
					<Box width={1} order={{ xs: 2, md: 1 }} display={'flex'} alignItems={'center'}>
						<Container>
							{/* <LeftSide /> */}
							<Box>
								<Box>
									<Box marginBottom={2} marginTop={{ xs: -1, md: 0 }}>
										<Typography component='h1'>
											<Typography
												color='text.primary'
												sx={{ fontWeight: 700, display: 'inline', lineHeight: 1.1 }}
												component={'span'}
												variant='h2'
											>
												{/* Ihr. */}
												{t('heroHeadline', { context: '1' })}
											</Typography>
											<Typography
												color='text.primary'
												// color={theme.palette.mode === 'light' ? 'text.secondary' : 'secondary.main'}
												// #666
												// #999
												// secondary.main
												// text.secondary
												component={'span'}
												variant='h2'
												sx={{ fontWeight: 700, display: 'inline', lineHeight: 1.1 }}
											>
												{' '}
												{t('heroHeadline', { context: '2' })}

												{/* Softwarepartner. */}
												{/* {t('heroHeadline', { context: '2' })} */}
											</Typography>
											<Typography
												color={theme.palette.mode === 'light' ? 'text.primary' : 'text.primary'}
												component={'span'}
												variant='h2'
												sx={{ fontWeight: 700, display: 'inline-block', lineHeight: 1.1 }}
											>
												{/* aus Frankfurt. */}
												{t('heroHeadline', { context: '3' })}
											</Typography>
										</Typography>
									</Box>

									<Box marginBottom={3}>
										{/* <span style={{ display: 'none' }}> */}
										<Typography
											display='inline'
											variant='h6'
											component='h2'
											color='text.secondary'
											sx={{ fontSize: '1.2rem !important', lineHeight: isMd ? 1.5 : 1.25 }}
										>
											{t('heroSubheadline', { context: '1' })}
											{/* Individuelle Softwareentwicklung für Unternehmen und Behörden.*/}
										</Typography>
										<Typography
											display={isMd ? 'inline' : 'block'}
											variant='h6'
											component='h2'
											color='text.primary'
											sx={{ fontSize: '1.15rem !important' }}
											marginTop={0.2}
											key={language + triggerAnimation}
										>
											<LazyMotion strict features={loadFeatures}>
												{' '}
												<b>
													{t('heroSubheadline', { context: '2' })
														.split('')
														.map((char, index) => {
															const key = `char-${index}`;
															let rnd = Math.random() * 1.1;
															return (
																<m.span
																	key={key}
																	variants={variants(rnd)}
																	// initial={{ opacity: 1 }}
																	// initial={false}
																	// animate={{ opacity: 1 }}
																	// animate={!isMd ||(cookieConsentIsSetTo && isMd) ? 'inactive' : 'active'}
																	initial={{ opacity: 0 }}
																	// initial={
																	// 	isMd || (triggerAnimation && !isMd)
																	// 		? { opacity: 0 }
																	// 		: { opacity: 1 }
																	// }
																	animate={
																		isMd || (triggerAnimation && !isMd) ? 'active' : 'inactive'
																	}
																	// initial={isMd ? { opacity: 0 } : false}
																	// animate={isMd ? 'active' : 'inactive'}
																	// animate={cookieConsentBoxVisible && !isMd ? 'stop' : 'blinking'}
																	// animate={{ opacity: 1 }}
																	// transition={{ repeat: 4, duration: 0.07, delay: 0.2 + rnd }}
																	// onAnimationComplete={() => {
																	// 	if (!animatedOnce) {
																	// 		setAnimatedOnce(true);
																	// 	}
																	// }}
																>
																	{char}
																</m.span>
															);
														})}
												</b>
											</LazyMotion>
											{/* <b>{t('heroSubheadline', { context: '2' })}</b> */}
										</Typography>
										{/* Resilient. Sicher. Compliant. */}
										{/* </span> */}
										<Box
											display='flex'
											flexDirection={{ xs: 'column', sm: 'row' }}
											alignItems={{ xs: 'stretched', sm: 'flex-start' }}
											marginTop={{ xs: 4, sm: 4 }}
										>
											<Link
												to={link_prefix + '/applications/'}
												prefetch='intent'
												style={{
													textDecoration: 'none',
												}}
												onClick={(e) => {
													e.stopPropagation();
													// e.preventDefault();
												}}
											>
												<Button
													variant='contained'
													color='primary'
													size='large'
													fullWidth={isMd ? false : true}
												>
													{t('heroButton', { context: '1' })}
												</Button>
											</Link>

											<Box
												marginTop={{ xs: 2, sm: 0 }}
												marginLeft={{ sm: 2 }}
												width={{ xs: '100%', sm: 'auto' }}
											>
												<Link
													// to='/about'
													to={link_prefix + '/about/'}
													prefetch='intent'
													style={{
														textDecoration: 'none',
													}}
													onClick={(e) => {
														e.stopPropagation();
														// e.preventDefault();
													}}
												>
													<Button
														variant='outlined'
														color='primary'
														size='large'
														fullWidth={isMd ? false : true}
													>
														{t('heroButton', { context: '2' })}
													</Button>
												</Link>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</Container>
					</Box>
					<Box
						sx={{
							flex: { xs: '0 0 100%', md: '0 0 50%' },
							position: 'relative',
							maxWidth: { xs: '100%', md: '49%', lg: '50%' },
							order: { xs: 1, md: 2 },
						}}
					>
						<Box
							sx={{
								width: { xs: 1, md: '49.5vw' },
								height: '100%',
								position: 'relative',
							}}
						>
							<Box
								sx={{
									overflow: 'hidden',
									left: '0%',
									width: 1,
									height: 1,
									position: { xs: 'relative', md: 'absolute' },
									clipPath: {
										xs: 'none',
										md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
									},
									shapeOutside: {
										xs: 'none',
										md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
									},
								}}
							>
								{/* <RightSide /> */}
								<Box
									sx={{
										height: { xs: 'auto', md: 1 },
										display: { xs: 'grid', md: 'inline-block' },
										position: 'relative',
										maxWidth: '100%',
										// right: { xs: 0, md: '100px' },
									}}
								>
									{/* <ImageLoader delay={false} intersectViewportCheck={false} childRef={coverRef}> */}
									{/* <Box
										ref={coverRef}
										// component='img'
										component='video'
										autoPlay
										loop
										muted
										playsInline //* for ios
										// onLoad={() => {
										//   // console.log('loaded');
										//   setLoadState({ loadState: 'loaded' });
										// }}

										// src={isSm ? heroVid_mobil : heroVid_desktop}
										src={heroVid_desktop}
										height={{ xs: 200, md: 1 }}
										style={{
											position: isMd ? 'relative' : 'static',
											width: isMd ? 'auto' : '100%',
											height: '100%',
											filter:
												theme.palette.mode === 'light' ? 'brightness(0.9)' : 'brightness(0.8)',
										}}
									/> */}
									<video
										ref={coverRef}
										// src={IS_MOBILE ? heroVid_mobil : heroVid_desktop}
										autoPlay
										loop
										muted
										playsInline //* for ios
										poster={hero_poster}
										style={{
											position: isMd ? 'relative' : 'static',
											width: isMd ? 'auto' : '100%',
											height: '100%',
											objectFit: 'cover',
											filter:
												theme.palette.mode === 'light' ? 'brightness(0.9)' : 'brightness(0.8)',
										}}
									/>
									<Divider
										sx={{
											display: isMd ? 'none' : 'block',
										}}
									/>
									<Divider
										sx={{
											display: isMd ? 'none' : 'block',
										}}
									/>
									<Box
										sx={{
											display: isMd ? 'block' : 'none',
											position: 'absolute',
											bottom: isMd ? '1px' : '8px',
											right: 5,
											zIndex: 100,
											mixBlendMode: 'difference',
											color: 'white',
										}}
									>
										<IconButton
											aria-label='play and pause'
											onClick={() => {
												if (coverRef.current != null) {
													if (coverRef.current.paused) {
														coverRef.current.play();
														setVideoPlaying(true);
													} else {
														coverRef.current.pause();
														setVideoPlaying(false);
													}
												}
											}}
										>
											{videoPlaying ? (
												// <PauseIcon
												//   sx={{
												//     mixBlendMode: 'difference',
												//     color: 'white',
												//     width: 30,
												//     height: 30,
												//   }}
												// />
												// svg
												// <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 24 24'>
												//   <path
												//     fill='white'
												//     d='M9 6h2v12H9zm4 0h2v12h-2z'
												//     style={{
												//       mixBlendMode: 'difference',
												//     }}
												//   />
												// </svg>

												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='128.000000pt'
													height='128.000000pt'
													viewBox='0 0 128.000000 128.000000'
													preserveAspectRatio='xMidYMid meet'
													style={{
														mixBlendMode: 'difference',
														// color: 'white',
														width: 30,
														height: 30,
													}}
												>
													<g
														transform='translate(0.000000,128.000000) scale(0.100000,-0.100000)'
														fill='white'
														stroke='none'
													>
														<path d='M420 640 l0 -400 50 0 50 0 0 400 0 400 -50 0 -50 0 0 -400z' />
														<path d='M780 640 l0 -400 50 0 50 0 0 400 0 400 -50 0 -50 0 0 -400z' />
													</g>
												</svg>
											) : (
												<PlayArrowIcon
													sx={{
														mixBlendMode: 'difference',
														color: 'white',
														width: 30,
														height: 30,
													}}
												/>
											)}
										</IconButton>
									</Box>
								</Box>
							</Box>
							{/* </Box> */}
						</Box>
					</Box>
				</Box>
			</Container>
			<Divider />
		</Box>
	);
};

export default Hero;
